class Stopwatch {
    constructor(delay, callbacks) {
        this.delay = delay;
        this.isRunning = false;
        if (callbacks) {
            this.onStart = callbacks.onStart;
            this.onTick = callbacks.onTick;
            this.onStop = callbacks.onStop;
        }
    }

    start() {
        if (this.isRunning) return;
        if (this.onStart) {
            this.onStart();
        }
        this.isRunning = true;
        const timerStart = Date.now(); // milliseconds
        let prevTime = Date.now();

        this.interval = setInterval(() => {
            const currentTime = Date.now(); // milliseconds
            this.timePassed = currentTime - prevTime;
            prevTime = currentTime;
            if (this.onTick) {
                this.onTick(this.timePassed);
            }
        }, this.delay);
    }

    stop() {
        if (!this.isRunning) return;

        if (this.onStop) {
            this.onStop(this.timePassed);
        }
        clearInterval(this.interval);
        this.isRunning = false;
    }
}

export default Stopwatch;
