<template>
    <v-app>
        <main class="video-player-container">
            <!-- START: Loading State -->
            <div v-if="playerState == 'loading'" class="loading-container">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                <p class="mt-1">Setting Up ...</p>
            </div>
            <!-- END: Loading State -->

            <!-- START: ERROR STATE -->
            <div v-if="playerState == 'error'">
                <p class="text-primary">Error Connecting</p>
            </div>
            <!-- END: ERROR STATE -->

            <!-- START: Upcoming State -->
            <div v-if="playerState == 'awaiting'" class="awaiting-container">
                <ul>
                    <li>
                        <h2 class="awaiting-container-service-title">
                            {{ hasLiveEvent ? 'Live Stream' : 'Recording' }} for
                            {{ service.firstName + ' ' + service.lastName }}
                        </h2>
                    </li>

                    <li v-for="event in upcomingEvents" :key="event.index" class="awaiting-container-service-entry">
                        <p v-if="event.time === '0001-01-01T00:00:00'">Awaiting Time</p>
                        <p v-else>{{ event.time | UTC_to_Local('MMMM DD, YYYY - h:mm A ') }}</p>
                        <h3>
                            <v-chip v-if="event.live" class="live-chip mr-2">• Live</v-chip>
                            {{ cleanTitle(event.title) }}
                        </h3>
                    </li>

                    <li v-if="availableEvents.length === 0 && upcomingEvents.length === 0">
                        <p class="awaiting-container-no-available">No events have been scheduled yet</p>
                    </li>

                    <p v-if="!hasLiveEvent" class="note-container">
                        * A recording of the service will be uploaded shortly after the event.
                    </p>
                </ul>
            </div>
            <!-- END: Upcoming State -->

            <!-- START: Video Player -->
            <video-player
                v-if="playerState == 'loaded'"
                :service="service"
                :options="settings"
                ref="videoPlayer"
                :slug="slug"
                :availableEvents="availableEvents"
                :test="isTest"
            />
            <!-- :ended="ended"
                :pausedId="pausedId" -->
            <!-- END: Video Player -->

            <img v-if="playerState == 'awaiting'" class="logo-watermark" :src="service.logoURL" />
        </main>
    </v-app>
</template>
<script>
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

import VideoPlayer from './components/VideoPlayer';

export default {
    props: {
        slug: {
            type: String,
        },
        playerOnly: {
            type: Boolean,
        },
        options: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            settings: {},
            playerState: 'loading',
            serviceStateOptions: {
                0: 'created',
                1: 'upcoming',
                2: 'live',
                3: 'in-between',
                4: 'finished',
                5: 'completed',
                7: 'paused',
            },
            eventStateOptions: {
                0: 'created',
                1: 'preview',
                2: 'live',
                3: 'finished',
                4: 'processing',
                5: 'ready',
                6: 'paused',
            },
            service: {
                events: [],
            },
            ended: 0,
            pausedId: 0,
            availableEvents: [],
            upcomingEvents: [],
            isTest: false,
            hasLiveEvent: false,
        };
    },
    mounted() {
        // Parse Options Prop
        this.settings = JSON.parse(this.options);

        // Start Sockets
        this.$socket.start({ log: true });
        this.$socket.invoke('JoinSlug', this.slug);

        // Get Service
        this.getServiceInfo();

        if (this.$auth) {
            var i = setInterval(this.checkIntercom(), 100);
            setTimeout(() => {
                clearInterval(i);
            }, 10000);
        }
    },
    methods: {
        checkIntercom() {
            let intercomContainer = document.querySelector('#intercom-container-body');
            if (intercomContainer) {
                intercomContainer?.classList.remove('intercom-show');
                intercomContainer.classList.add('intercom-hidden');
            }
        },
        cleanTitle: function(title) {
            let cleaned = title.replace(`${this.service.firstName} ${this.service.lastName}'s `, '');
            return cleaned;
        },
        getServiceInfo() {
            this.axios
                .get(process.env.VUE_APP_API + `/services/view/${this.slug}`)
                .then(response => {
                    this.service = { ...response.data };
                    let serviceState = this.service.serviceState;

                    this.isTest = response.data.test;

                    // Make a list of available to select events
                    this.availableEvents = this.service.events.filter(event => {
                        if (event.eventStatus === 1) {
                            return false;
                        } else {
                            return true;
                        }
                    });

                    this.upcomingEvents = this.service.events.filter(event => {
                        if (event.eventStatus === 0) {
                            // Check if Time is unset
                            if (event.time !== '0001-01-01T00:00:00') {
                                return true;
                            } else {
                                return false;
                            }
                        } else return true;
                    });

                    this.upcomingEvents.forEach(event => {
                        if (event.live) {
                            this.hasLiveEvent = true;
                        }
                    });

                    // Update UI States
                    if (
                        this.serviceStateOptions[serviceState] === 'created' ||
                        this.serviceStateOptions[serviceState] === 'upcoming' ||
                        this.availableEvents.length === 0
                    ) {
                        this.playerState = 'awaiting';
                    } else {
                        // This prevents the video player from refreshing, instead
                        // we are just updated the data structure
                        if (this.playerState != 'loaded') this.playerState = 'loaded';
                    }
                })
                .then(() => {
                    // If there is a live event ID set that to the active video
                    if (this.service.currentLiveEventId > 0) {
                        let event = this.service.events.find(event => event.id == this.service.currentLiveEventId);
                        this.setActiveVideo(event);
                    }
                })
                .catch(error => {
                    this.playerState = 'error';
                });
        },
        setActiveVideo(event) {
            this.$refs.videoPlayer.setActiveVideo(event);
        },
    },
    sockets: {
        async NotifyLive(data) {
            this.getServiceInfo();

            // Find Event with the ID of the Event ID
            // let event = this.service.events.find(event => event.id == data.eventId);

            // Switch to Live Video
            // if (event && this.eventStateOptions[data.streamStatus] === 'live') {
            //     await sleep(1000);
            //     this.getServiceInfo();
            // } else if (event && this.eventStateOptions[data.streamStatus] === 'finished') {
            //     this.ended = data.eventId;
            // } else if (event && this.eventStateOptions[data.streamStatus] === 'paused') {
            //     this.pausedId = data.eventId;
            // } else {
            //     this.ended = 0;
            // }
        },
        NotifyEvent(data) {
            // let item = this.events.find(x => x.id == data.id);
            // item = data;
            // this.$refs.video.setServiceState();
        },
    },
    components: {
        VideoPlayer,
    },
};
</script>

<style lang="scss">
.intercom-launcher {
    display: none !important;
}

.intercom-container-body {
    display: none !important;
}

#intercom-container-body {
    display: none !important;
}
.intercom-hidden {
    display: none !important;
}

.video-player-container {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #333333;
    overflow: hidden;

    .note-container {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        color: white;
        max-width: min(25%, 350px);
    }

    .live-chip {
        background: #df2000 !important;
        color: white !important;
        padding: 0 !important;
        font-size: 12px !important;
        height: 20px !important;
        width: 40px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border-radius: 4px !important;
    }

    .logo-watermark {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        max-width: min(15%, 150px);
    }

    .loading-container {
        text-align: center;
    }

    .awaiting-container {
        background: #333333;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: auto;
        position: relative;

        .awaiting-img {
            max-height: 100%;
            max-width: 100%;
        }

        ul {
            list-style: none;
            margin-top: 0rem;
            width: 100%;
            margin: 0;
            padding: 0;
            margin-top: auto;
            margin-bottom: auto;
            width: 600px;
            padding: 2rem;

            li {
                width: 100%;
            }
        }

        &-no-available {
            color: white;
            width: 100%;
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        &-service-title {
            color: white;
            border-bottom: 1px solid #c2c2c2;
            width: 100%;
            font-size: 1.7rem;
            text-align: center;
        }

        &-service-entry {
            color: white;
            margin-top: 2rem;
            text-align: center;

            h3 {
                font-weight: 300;
                font-size: 1rem;
                margin: 0;
            }

            p {
                font-weight: normal;
                font-size: 1.8rem;
                margin: 0;
            }
        }
    }
}

@media (max-width: 599px) {
    .video-player-container .note-container {
        text-align: center;
        font-size: 10px;
        min-width: 100%;
        padding: 0 0.5rem;
        position: absolute;
        left: 0;
        top: 0;
        height: 10%;
    }

    .awaiting-container {
        &-service-title {
            font-size: 1.4rem !important;
        }

        &-service-entry {
            margin-top: 1rem !important;

            h3 {
                font-size: 0.8rem !important;
            }

            p {
                font-size: 1.2rem !important;
            }
        }
    }
}
</style>
