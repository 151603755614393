import axios from 'axios';
import { getWithExpiry, setWithExpiry } from './localStorage.js';
import Stopwatch from './stopwatch.js';

const startEndpoint = process.env.VUE_APP_API + '/dogs/track';
const updateEndpoint = process.env.VUE_APP_API + '/dogs/update';

// interval of time (in seconds) to update the server and localStorage
const UPDATE_INTERVAL = 60;

// interval of time (in milliseconds) between ticks in stopwatch
const DELAY = 1000;
const STORAGE_TTL = 21600000;

/** Responsible for tracking the duration of views for events */
class ViewTracker {
    constructor(player, slug) {
        this.player = player;
        this.slug = slug;
        this.currentEvent = null;
        this.stopwatch = new Stopwatch(DELAY, {
            onTick: duration => {
                if (this.currentEvent === null) return;

                // this.currentEvent.watchDuration += Math.round(duration / 1000); // Get duration in seconds
                this.currentEvent.duration += Math.round(duration / 1000); // Get duration in seconds

                // Update local storage and server with current values every UPDATE_INTERVAL seconds
                // if (this.currentEvent.watchDuration % UPDATE_INTERVAL < 1) {
                if (this.currentEvent.duration % UPDATE_INTERVAL < 1) {
                    this.updateLocalStorage();
                    this.updateServer();
                }
            },
            onStop: () => {
                if (!this.stopwatch.isRunning) return;

                this.updateLocalStorage();
                this.updateServer();
            },
        });

        const onPlaying = () => {
            this.stopwatch.start();
        };

        const onPause = () => {
            this.stopwatch.stop();
        };

        const onWaiting = () => {
            this.stopwatch.stop();
        };

        player.on('playing', onPlaying);
        player.on('pause', onPause);
        player.on('waiting', onWaiting);
    }

    async changeEvent(eventId, isLive) {
        this.stopwatch.stop(); // Stop timer and update local storage and server with latest data for previous event

        const localData = getWithExpiry(eventId);
        if (localData) {
            this.currentEvent = {
                ...localData,
                playCount: localData.playCount + 1,
                isLive,
            };
        } else {
            // No recent viewing data was found in localStorage for this event
            const response = await this.notifyServer(eventId, isLive, this.slug);
            const { videoId, id } = response;
            this.currentEvent = {
                // watchDuration: 0,
                duration: 0,
                playCount: 1,
                slug: this.slug,
                id,
                eventId,
                videoId,
                isLive,
            };
        }
        this.stopwatch.start();
    }

    updateLocalStorage = () => {
        setWithExpiry(this.currentEvent.eventId, this.currentEvent, STORAGE_TTL);
    };

    notifyServer(eventId, isLive, slug) {
        const payload = {
            eventId,
            live: isLive,
            slug,
            start: 0,
            stop: 0,
            duration: 0,
        };
        return axios
            .post(startEndpoint, payload)
            .then(({ data }) => {
                return data;
            })
            .catch(error => {
                console.error('Error in notifyServer()');
            });
    }

    updateServer = () => {
        let payload = {
            ...this.currentEvent,
            live: this.currentEvent.isLive,
        };

        delete payload.isLive;

        axios
            .post(updateEndpoint, payload)
            .then(response => {})
            .catch(error => {
                console.error('Error in updateTracking()');
            });
    };
}

export default ViewTracker;
