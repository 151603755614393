// ============================================================================
// Vue Stuff
// ============================================================================
import Vue from 'vue';
import App from './App.vue';
import vueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';

// ============================================================================
// Veutify
// ============================================================================
import vuetify from './plugins/vuetify';
App.vuetify = vuetify;

// ============================================================================
// Axios
// ============================================================================
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

// ============================================================================
// Web Sockets
// ============================================================================
import VueSignalR from '@latelier/vue-signalr';
Vue.use(VueSignalR, process.env.VUE_APP_SIGNALR_HUBS + '/viewing');

// ============================================================================
// Moment Time Library
// ============================================================================
import moment from 'moment';
Vue.filter('UTC_to_Local', function(date, format) {
    return moment
        .utc(date)
        .local()
        .format(format);
});

// ============================================================================
// Custom Element
// ============================================================================

Vue.use(vueCustomElement);
Vue.customElement('memoryshare-stream', App);

// ============================================================================
// These were involed in the old Video Player. Need to Verify if being used
// Or if can be deleted
// ============================================================================

// import VueLayers from 'vuelayers';
// import 'vuelayers/lib/style.css';
// import 'vuetify/dist/vuetify.min.css';
// import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
// import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line
// delete L.Icon.Default.prototype._getIconUrl;
// eslint-disable-next-line
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

// Vue.component("l-map", LMap);
// Vue.component("l-tile-layer", LTileLayer);
// Vue.component("l-marker", LMarker);
// Vue.component("l-popup", LPopup);
// Vue.component("v-marker-cluster", Vue2LeafletMarkerCluster);
// Vue.use(VueLayers);
