// Set local storage item with expiration (default is 6 houros)
function setWithExpiry(key, value, ttl = 21600000) {
    const now = new Date();

    const item = {
        value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item));
}

// retrieve local storage item with expiration
function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // if item doesn't exist, return null
    if(!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // if item is expired, return null
    if(now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

export { getWithExpiry, setWithExpiry};